'use client';
import BasicImage from 'core/BasicImage';
import { sanitize } from 'isomorphic-dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import NewProjectImage from 'shared/sections/NewProjectContainer/NewProject/NewProjectImage';

import cn from './style.module.sass';

type CurrentListItem = {
    newImg?: string;
    oldImg?: string;
    icons?: IconsItems[];
    video?: string;
    title: string;
    description: string;
    titlesNew?: TitlesItems[];
};

type TitlesItems = {
    title?: string;
};

type IconsItems = {
    icon?: string;
};

export default function NewProject({
    title,
    description,
    newImg,
    oldImg,
    video,
    icons,
    titlesNew,
}: CurrentListItem) {
    const ref = useRef<HTMLVideoElement | null>(null);
    const isMobile = useMedia('(max-width: 750px)');

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    }, []);

    const stopMovie = () => {
        if (!video) return;
        ref?.current?.pause();
    };

    const playMovie = () => {
        if (!video) return;
        ref.current?.play().catch(console.log);
    };

    console.log({ titlesNew });

    return (
        <>
            {isMount && (
                <div className={cn['new-project']}>
                    <div
                        className={cn['new-project__item']}
                        onMouseOver={playMovie}
                        onMouseOut={stopMovie}
                    >
                        {video && !isMobile ? (
                            <div className={cn['new-project__imageContainer']}>
                                <video
                                    ref={ref}
                                    muted={true}
                                    playsInline={true}
                                    loop={true}
                                    className={cn['new-project__video']}
                                >
                                    <source
                                        src={`${process.env.NEXT_PUBLIC_CONTENT_URL}${video}`}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        ) : (
                            <NewProjectImage newImg={newImg} oldImg={oldImg} />
                        )}

                        <div className={cn['new-project__block']}>
                            {icons && icons.length > 0 && (
                                <div className={cn['new-project__icons']}>
                                    {icons.map(item => (
                                        <div key={item.icon} className={cn['new-project__icon']}>
                                            <BasicImage
                                                width={40}
                                                height={40}
                                                src={item.icon as string}
                                                alt="icon"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {titlesNew && titlesNew.length > 0 && (
                                <div className={cn['new-project__icons']}>
                                    <div className={cn['new-project__ListNew']}>
                                        {titlesNew &&
                                            titlesNew.map(item => (
                                                <div
                                                    key={item.title}
                                                    className={cn['new-project__titleNew']}
                                                >
                                                    {item.title}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                            <div
                                dangerouslySetInnerHTML={{ __html: sanitize(title) }}
                                className={cn['new-project__title']}
                            />
                            <div
                                dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                                className={cn['new-project__description']}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
