import BasicImage from 'core/BasicImage';
import React from 'react';

import cn from './style.module.sass';

type NewProjectImageProps = {
    newImg?: string;
    oldImg?: string;
};

export default function NewProjectImage({ newImg, oldImg }: NewProjectImageProps) {
    return newImg ? (
        <div className={cn['new-project__imageContainer']}>
            <BasicImage
                src={newImg}
                alt="case-img"
                className={cn['new-project__imageNew']}
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: 'auto' }} // optional
            />
        </div>
    ) : (
        <div className={cn['new-project__imageContainer']}>
            <BasicImage
                src={oldImg as string}
                alt="case-img"
                className={cn['new-project__imageOld']}
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: 'auto' }} // optional
            />
        </div>
    );
}
